import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
  IconButton,
  Grid,
  Divider,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import VisaIcon from "@mui/icons-material/CreditCard"; // You may need to import an appropriate icon
import MasterCardIcon from "@mui/icons-material/CreditCard"; // You may need to import an appropriate icon
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";

function Footer() {
  const handleWhatsAppClick = () => {
    window.location.href = "https://wa.me/message/HOYXZTTFNXNZH1";
  };

  return (
    <Box
      sx={{
        backgroundColor: "#4FA41B ",
        color: "#fff",
        padding: "2rem",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Ayuda</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Metodos de Entrega</Typography>
              <Typography>Legales</Typography>
              <Typography>Terminos y Condiciones</Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">Síguenos</Typography>
          <IconButton
            href="https://facebook.com"
            target="_blank"
            color="inherit"
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            href="https://instagram.com"
            target="_blank"
            color="inherit"
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            href="https://twitter.com"
            target="_blank"
            color="inherit"
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            href="https://youtube.com"
            target="_blank"
            color="inherit"
          >
            <YouTubeIcon />
          </IconButton>
          <IconButton href="https://tiktok.com" target="_blank" color="inherit">
            Tiktok
          </IconButton>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">Medios de Pago</Typography>
          <IconButton color="inherit">
            <VisaIcon />
            Visa
          </IconButton>
          <IconButton color="inherit">
            <MasterCardIcon />
            Mastercard
          </IconButton>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">Atención al Cliente</Typography>
          <Typography>+542604238024</Typography>
          <Typography>
            San Rafael, Mendoza
          </Typography>
          <IconButton color="inherit">
            <HeadsetMicIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Divider sx={{ margin: "2rem 0", backgroundColor: "white" }} />
      <Link
        href="https://webprofitlandingpage.onrender.com/"
        color="secondary"
        underline="none"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography variant="body2" align="center" sx={{ color: "#fff" }}>
          &copy; 2024 Powered By Webprofit
        </Typography>
      </Link>
    </Box>
  );
}

export default Footer;
