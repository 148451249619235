import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Divider,
  ListItemIcon,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import RoomIcon from "@mui/icons-material/Room";
import SearchIcon from "@mui/icons-material/Search";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhoneIcon from "@mui/icons-material/Phone";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import "../styles/navbar.css";
import detailNavbar from "../images/FeedIG/DetailNavBar.png";

function Navbar({ setSearchbarVisible, setSelectedCategory }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false); // Estado del drawer
  const { user, isAuthenticated, logout } = useAuth();
  let firstChar = "";
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleDrawerToggleCart = () => {
    navigate("/pay");
  };

  const handleloginpage = () => {
    navigate("/login");
  };
  const handleregisterpage = () => {
    navigate("/register");
  };
  const handlehomepage = () => {
    navigate("/");
  };

  const handleWhatsAppClick = () => {
    // Reemplaza 'tu-enlace-de-whatsapp' con el enlace real de WhatsApp
    window.location.href = "https://wa.me/message/FIS5AUGAHMJPO1";
  };
  const handleResumeButtonClick = () => {
    const resume = document.getElementById("ofertas");
    resume.scrollIntoView({ behavior: "smooth" });
  };
  if (isAuthenticated) {
    if (user && user.username && user.lastname) {
      firstChar = user.username.charAt(0).toUpperCase();
      firstChar += user.lastname.charAt(0).toUpperCase();
    } else {
      // Manejar el caso en que user, username o lastname no estén definidos
      firstChar = ""; // O alguna otra lógica por defecto
    }
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOrders = () => {
    navigate("/orders");
  };

  const handleLogout = async () => {
    console.log();
    logout();
  };
  const handleNull = async () => {
    console.log("XD");
  };
  const handleAccountClick = () => {
    if (isAuthenticated) {
      setDrawerOpen(true);
    } else {
      navigate("/login");
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      {isAuthenticated ? (
        <Box>
          {user && user.rol === "admin" ? (
            // Contenido a mostrar si el usuario está autenticado
            <div>
              <IconButton
                aria-label="menu"
                sx={{ color: "black" }}
                onClick={handleOrders}
              >
                <RoomServiceIcon color="error" fontSize="large" />
              </IconButton>
            </div>
          ) : (
            ""
          )}
          <List>
            <ListItem>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "2vh",
                }}
              >
                <Avatar
                  style={{ color: "black", fontWeight: "bold" }}
                  sx={{ width: 64, height: 64, marginRight: "2vh" }}
                >
                  {isAuthenticated ? firstChar : ""}
                </Avatar>
                <Typography color="textPrimary" variant="h5">
                  {`Hola, ${user?.username || "Guest"} ${user?.lastname || ""}`}
                </Typography>
              </Box>
            </ListItem>
            <Divider />
          </List>

          <List>
            <ListItem button onClick={handleClose}>
              <ListItemIcon>
                <ShoppingCart fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Mis Compras" />
            </ListItem>
          </List>

          <Typography
            style={{ fontWeight: "bold" }}
            sx={{ ml: 3, mt: 1 }}
            variant="h6"
          >
            Categorías
          </Typography>
          <Divider />
          <ul className="mobile-navigation">
            <li>
              <Button
                onClick={() => {
                  setSelectedCategory("Congelados");
                  navigate("/catalog");
                }}
              >
                Congelados
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  navigate("/");
                }}
              >
                Tartas Veganas (Proximamente)
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  navigate("/");
                }}
              >
                Empanadas Veganas (Proximamente)
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  navigate("/");
                }}
              >
                Jugos Verdes (Proximamente)
              </Button>
            </li>
          </ul>

          <Typography
            style={{ fontWeight: "bold" }}
            sx={{ ml: 3, mt: 4 }}
            variant="h6"
          >
            Cuenta
          </Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemText
                style={{ color: "grey" }}
                primary={user ? user.email : "Email no disponible"}
              />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Cerrar Sesión" />
            </ListItem>
          </List>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{ marginLeft: "3vh", marginTop: "6vh", marginBottom: "3vh" }}
            display={"flex"}
            textAlign={"left"}
            alignItems={"center"}
          >
            <Box sx={{ marginLeft: "3vh" }}>
              <Typography text fontSize={"2rem"}>
                ¡Hola!
              </Typography>
              <Typography fontSize={"0.9rem"}>
                Bienvenido a{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    fontFamily: "'Segoe Script', Arial, sans-serif",
                    color: "#4FA41B ",
                  }}
                >
                  Vital Veg
                </span>{" "}
                Ingresa a tu cuenta o regístrate
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} textAlign={"center"} justifyContent={"center"}>
            <Button
              onClick={handleloginpage}
              size="small"
              sx={{ margin: "1vh" }}
              variant="contained"
            >
              Iniciar Sesión
            </Button>
            <Button
              onClick={handleregisterpage}
              sx={{ margin: "1vh" }}
              size="small"
              variant="contained"
            >
              Regístrate
            </Button>
          </Box>
          <Typography
            style={{ fontWeight: "bold" }}
            sx={{ ml: 3, mt: 1 }}
            variant="h6"
          >
            Categorías
          </Typography>
          <Divider />
          <ul className="mobile-navigation">
            <li>
              <Button
                onClick={() => {
                  setSelectedCategory("Congelados");
                  navigate("/");
                }}
              >
                Congelados
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  setSelectedCategory("Diseño Gráfico");
                  navigate("/");
                }}
              >
                Tartas Veganas
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  setSelectedCategory("Marketing Digital");
                  navigate("/");
                }}
              >
                Empanadas Veganas
              </Button>
            </li>
          </ul>
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className="appbar" position="static" color="white">
        <Toolbar
          style={{ background: "white" }}
          sx={{
            marginLeft: {
              xs: "0vh",
              sm: "0vh",
              md: "0vh",
              lg: "2vh",
              xl: "10vh",
            },
            marginRight: {
              xs: "0vh",
              sm: "0vh",
              md: "0vh",
              lg: "2vh",
              xl: "10vh",
            },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box display={"flex"} sx={{ alignItems: "center" }}>
            <IconButton
              aria-label="menu"
              sx={{ color: "#4FA41B " }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Link
              to="/"
              style={{
                fontWeight: "bold",
                textDecoration: "none",
                color: "#fff",
                fontFamily: "'Segoe Script', Arial, sans-serif",
              }}
            >
              <Box
                sx={{
                  left: {
                    xs: "34%",
                    sm: "20%",
                    md: "16%",
                    lg: "12%",
                    xl: "7%",
                  },
                }}
                className="circleContainer"
              >
                <Box className="backgDetailNavbar">
                  <Typography
                    sx={{
                      marginLeft: {
                        xs: "3vh",
                      },
                      marginRight: {
                        xs: "1vh",
                      },
                      fontSize: {
                        xs: "1.4rem",
                        sm: "1.7rem",
                        md: "2rem",
                        lg: "2.4rem",
                        xl: "2.4rem",
                      },
                    }}
                    style={{
                      fontWeight: "bold",
                      color: "#4FA41B ",
                      fontFamily: "'Segoe Script', Arial, sans-serif",
                    }}
                  >
                    VitalVeg
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  mb: "none",
                  lg: "none",
                  xl: "none",
                },
                marginLeft: {
                  xs: "1vh",
                },
                marginRight: {
                  xs: "1vh",
                },
              }}
              edge="end"
              color="primary"
              aria-label="search"
              onClick={() => setSearchbarVisible((prev) => !prev)}
            >
              <SearchIcon fontSize="large" />
            </IconButton>
            <IconButton
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                  mb: "flex",
                  lg: "flex",
                  xl: "flex",
                },
                marginLeft: {
                  xs: "1vh",
                },
                marginRight: {
                  xs: "1vh",
                },
              }}
              edge="end"
              color="primary"
              aria-label="search"
              onClick={() => handlehomepage((prev) => !prev)}
            >
              <HomeIcon fontSize="large" />
            </IconButton>
            {isAuthenticated ? (
              <Box>
                <IconButton
                  sx={{
                    marginLeft: {
                      xs: "1vh",
                    },
                    marginRight: {
                      xs: "1vh",
                    },
                  }}
                  onClick={handleAccountClick}
                  size="small"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>
                    {isAuthenticated ? firstChar : ""}
                  </Avatar>
                </IconButton>
              </Box>
            ) : (
              <IconButton
                sx={{
                  marginLeft: {
                    xs: "1vh",
                  },
                  marginRight: {
                    xs: "1vh",
                  },
                }}
                edge="end"
                color="primary"
                aria-label="account"
                onClick={handleAccountClick}
              >
                <AccountCircle fontSize="large" />
              </IconButton>
            )}

            <IconButton
              sx={{
                marginLeft: {
                  xs: "1vh",
                },
                marginRight: {
                  xs: "1vh",
                },
              }}
              edge="end"
              color="primary"
              aria-label="cart"
              onClick={handleDrawerToggleCart}
            >
              <LocalGroceryStoreIcon fontSize="large" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: "300px" },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Navbar;
