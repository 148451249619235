import { Box, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SpaIcon from "@mui/icons-material/Spa";
import LocationOnIcon from "@mui/icons-material/LocationOn";

function Banner1() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1536 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1536, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Box sx={{ paddingTop:"2vh",paddingBottom: "2vh", backgroundColor: "#f0f0d4" }}>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        showDots={false}
        arrows={false}
        renderButtonGroupOutside={false}
        ssr={true}
        animationDuration={100}
        swipeable={false}
        draggable={false}
        shouldResetAutoplay={false}
        focusOnSelect={false}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ textAlign: "center", padding: "20px 0" }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "0.9rem",
                sm: "1rem",
                md: "1.5rem",
                lg: "1.5rem",
                xl: "1.5rem",
              },
              fontWeight: "bold",
              color: "#2B4141",
              display: "flex",
              alignItems: "center",
            }}
          >
            Comida Vegana y Vegetariana
            <SpaIcon sx={{ ml: 1, fontSize: 25 }} />
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ textAlign: "center", padding: "20px 0" }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "0.9rem",
                sm: "1rem",
                md: "1.5rem",
                lg: "1.5rem",
                xl: "1.5rem",
              },
              fontWeight: "bold",
              color: "#2B4141",
              display: "flex",
              alignItems: "center",
            }}
          >
            San Rafael, Mendoza
            <LocationOnIcon sx={{ ml: 1, fontSize: 25 }} />
          </Typography>
        </Box>
      </Carousel>
    </Box>
  );
}

export default Banner1;
