import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import cocinando2 from "../images/FeedIG/cocinando2.jpg";
import cocinando3 from "../images/FeedIG/cocinando3.jpg";
import { useNavigate } from "react-router-dom";
import "../styles/animatedux.css";
import "../styles/lluvia.css";

export function SegmentResponsive() {
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const navigate = useNavigate();

  const handleClick1 = () => {
    navigate("/recipe");
  };

  const handleClick2 = () => {
    navigate("/catalog");
  };

  const handleResumeButtonClick = () => {
    const resume = document.getElementById("promos");
    resume.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box position="relative">
      <div className="leaves-container">
        <div className="leaf"></div>
        <div className="leaf"></div>
        <div className="leaf"></div>
        <div className="leaf"></div>
        <div className="leaf"></div>
        <div className="leaf"></div>
      </div>

      <Grid container>
        <Grid item xs={12} sm={12} mb={6} lg={6} xl={6}>
          <Box>
            <Typography
              sx={{
                marginTop: {
                  xs: "5vh",
                  sm: "2rem",
                  md: "2rem",
                  lg: "2rem",
                  xl: "7vh",
                },
                fontSize: {
                  xs: "2rem",
                  sm: "2rem",
                  md: "3rem",
                  lg: "3rem",
                  xl: "4rem",
                },
                opacity: 0,
                animation: "fadeIn 1s ease-out forwards",
                animationDelay: "0.5s",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
              color={"#2B4141"}
            >
              Transformá tus Comidas con{" "}
              <span
                style={{
                  fontFamily: "'Segoe Script', Arial, sans-serif",
                  fontWeight: "bold",
                  color: "#77b300",
                }}
              >
                VitalVeg
              </span>{" "}
            </Typography>
            <Typography
              sx={{
                marginLeft: {
                  xs: "5vh",
                  sm: "5vh",
                  md: "5vh",
                  lg: "5vh",
                  xl: "10vh",
                },
                marginRight: {
                  xs: "5vh",
                  sm: "2rem",
                  md: "2rem",
                  lg: "2rem",
                  xl: "10vh",
                },
                marginTop: {
                  xs: "5vh",
                  sm: "2rem",
                  md: "2rem",
                  lg: "2rem",
                  xl: "2rem",
                },
                fontSize: {
                  xs: "0rem",
                  sm: "0rem",
                  md: "1rem",
                  lg: "2rem",
                  xl: "2.3rem",
                },
                opacity: 0,
                animation: "fadeIn 1s ease-out forwards",
                animationDelay: "0.5s",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
              color={"#2B4141"}
            >
              Disfruta de nuestras deliciosas comidas veganas, listas para
              llevar y cocinar en minutos. En{" "}
              <span
                style={{
                  fontFamily: "'Segoe Script', Arial, sans-serif",
                  fontWeight: "bold",
                  color: "#77b300",
                }}
              >
                VitalVeg
              </span>{" "}
              , hacemos fácil y accesible el comer sano sin sacrificar el sabor.
              Haz tu pedido hoy y descubre lo sencillo que es comer bien.{" "}
            </Typography>
          </Box>

          <Box display="flex" justifyContent={"center"}>
            <Button
              onClick={handleResumeButtonClick}
              variant="contained"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "2rem",
                  md: "2rem",
                  lg: "2rem",
                  xl: "1.8rem",
                },
                marginTop: {
                  xs: "0vh",
                  sm: "0vh",
                  md: "0vh",
                  lg: "0vh",
                  xl: "5vh",
                },
                opacity: 0,
                animation: "fadeIn 1s ease-out forwards",
                animationDelay: "3s",
                alignItems: "center",
                justifyContent: "center",
                justifyItems: "center",
                alignContent: "center",
              }}
            >
              Ver Promociones 👇
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} mb={6} lg={6} xl={6}>
          <Box
            sx={{
              marginTop: {
                xs: "3vh",
                sm: "3vh",
                md: "3vh",
                lg: "3vh",
                xl: "5vh",
              },
              marginRight: {
                xs: "2vh",
                sm: "2rem",
                md: "2rem",
                lg: "2rem",
                xl: "2rem",
              },
              marginLeft: {
                xs: "2vh",
                sm: "2rem",
                md: "2rem",
                lg: "2rem",
                xl: "2rem",
              },
              opacity: 0,
              animation: "fadeIn 1s ease-out forwards",
              animationDelay: "0.5s",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "48%",
                  opacity: 0,
                  animation: "fadeInMove 1s ease-in forwards",
                  animationDelay: "1.5s",
                }}
                style={{
                  filter: hover1
                    ? "brightness(1.2) grayscale(0.3)"
                    : "brightness(1) grayscale(0)",
                }}
                onMouseOver={() => setHover1(true)}
                onMouseOut={() => setHover1(false)}
                onClick={handleClick1}
              >
                <img
                  src={cocinando2}
                  alt="Descubre VitalVeg"
                  style={{
                    width: "100%",
                    borderRadius: "12px",
                    filter: hover1
                      ? "brightness(1.2) grayscale(0.3)"
                      : "brightness(1) grayscale(0)",
                  }}
                />
                <Box
                  className="overlay"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.5)",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: hover1 ? 1 : 0,
                    transition: "opacity 0.3s, transform 0.3s",
                    transform: hover1 ? "translateY(0)" : "translateY(100%)",
                    textAlign: "center",
                    borderRadius: "12px",
                    padding: "1em",
                    boxSizing: "border-box",
                    animation: "fadeInMove 1s ease-in forwards",
                    animationDelay: "2s",
                  }}
                >
                  <div>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "1.4rem",
                          sm: "2rem",
                          md: "2rem",
                          lg: "2rem",
                          xl: "3rem",
                        },
                      }}
                    >
                      Recetas Saludables
                    </Typography>
                  </div>
                </Box>
              </Box>

              <Box
                sx={{
                  position: "relative",
                  width: "48%",
                  opacity: 0,
                  animation: "fadeInMove 1s ease-in forwards",
                  animationDelay: "1.5s",
                }}
                style={{
                  filter: hover2
                    ? "brightness(1.2) grayscale(0.3)"
                    : "brightness(1) grayscale(0)",
                }}
                onMouseOver={() => setHover2(true)}
                onMouseOut={() => setHover2(false)}
                onClick={handleClick2}
              >
                <img
                  src={cocinando3}
                  alt="Descubre VitalVeg"
                  style={{
                    width: "100%",
                    borderRadius: "12px",
                    filter: hover2
                      ? "brightness(1.2) grayscale(0.3)"
                      : "brightness(1) grayscale(0)",
                  }}
                />
                <Box
                  className="overlay1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.5)",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: hover2 ? 1 : 0,
                    transition: "opacity 0.3s, transform 0.3s",
                    transform: hover2 ? "translateY(0)" : "translateY(100%)",
                    textAlign: "center",
                    borderRadius: "12px",
                    padding: "1em",
                    boxSizing: "border-box",
                    animation: "fadeInMove 1s ease-in forwards",
                    animationDelay: "2.5s",
                  }}
                >
                  <div>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "1.4rem",
                          sm: "2rem",
                          md: "2rem",
                          lg: "2rem",
                          xl: "3rem",
                        },
                      }}
                    >
                      Nuestras Productos Veganos
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <style jsx>{`
        /* Contenedor de las hojas */
        .leaves-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 100; /* Poner al frente */
          pointer-events: none; /* No interferir con otros elementos */
        }

        /* Estilos de las hojas */
        .leaf {
          position: absolute;
          width: 25px;
          height: 15px;
          background-color: #50a41be1;
          border-radius: 60% 40% 60% 40% / 40% 60% 40% 60%;
          opacity: 0;
          animation: fallLeaf 12s linear infinite;
          transform: rotate(45deg);
        }

        .leaf:nth-child(1) {
          top: -20px;
          left: 10%;
          animation-delay: 0s;
        }

        .leaf:nth-child(2) {
          top: -20px;
          left: 30%;
          animation-delay: 1.3s;
        }

        .leaf:nth-child(3) {
          top: -20px;
          left: 50%;
          animation-delay: 2s;
        }

        .leaf:nth-child(4) {
          top: -20px;
          left: 70%;
          animation-delay: 2.4s;
        }

        .leaf:nth-child(5) {
          top: -20px;
          left: 90%;
          animation-delay: 3.7s;
        }

        .leaf:nth-child(6) {
          top: -20px;
          left: 20%;
          animation-delay: 5s;
        }

        /* Animación de las hojas */
        @keyframes fallLeaf {
          0% {
            opacity: 0.5;
            transform: rotate(45deg) translateX(0);
          }
          100% {
            opacity: 2;
            top: 100%;
            transform: rotate(185deg) translateX(400px);
          }
        }

        /* Otros estilos */
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @keyframes fadeInMove {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </Box>
  );
}

export default SegmentResponsive;
