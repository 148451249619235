import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  Drawer,
  Container,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Grid,
  Link,
} from "@mui/material";

import { Fab } from "@mui/material";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/homepage.css";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import { useNavigate } from "react-router-dom";
import Searchbar from "../components/Searchbar";
import Carrousel from "../components/Carrousel";
import SegmentResponsive from "../components/SegmentResponsive";

// Componente ShoppingCart

function HomePage({
  products,
  searchbarVisible,
  setDrawerOpenCart,
  drawerOpenCart,
  checkedTACC,
  setCheckedTACC,
  checkedGluten,
  setCheckedGluten,
  searchTerm,
  setSearchTerm,
  selectedCategory,
  setSelectedCategory,
  cartItems,
  setCartItems,
  play,
}) {

  
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationData, setAnimationData] = useState({
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });
  // Datos de ejemplo de itemos
  const navigate = useNavigate();

  const cartbuttonfab = () => {
    navigate("/pay");
  };

  return (
    <Box style={{ backgroundColor: "#eff0d0" }}>
      <Fab
        size="large"
        color="inherit"
        aria-label="whatsapp"
        style={{ position: "fixed", bottom: 20, right: 20 }}
        onClick={cartbuttonfab}
      >
        <LocalGroceryStoreIcon />
        {cartItems.length > 0 && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "red",
              borderRadius: "50%",
              padding: "0px 7px",
              color: "white",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            <Typography style={{ color: "white" }}>
              {cartItems.length}
            </Typography>
          </Box>
        )}
      </Fab>

      <SegmentResponsive play={play}></SegmentResponsive>

      {searchbarVisible && (
        <Searchbar
          checkedTACC={checkedTACC}
          setCheckedTACC={setCheckedTACC}
          checkedGluten={checkedGluten}
          setCheckedGluten={setCheckedGluten}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          cartItems={cartItems}
          setCartItems={setCartItems}
          drawerOpenCart={drawerOpenCart}
          setDrawerOpenCart={setDrawerOpenCart}
        />
      )}

      <Carrousel
        isAnimating={setIsAnimating}
        setIsAnimating={setIsAnimating}
        products={products}
        checkedTACC={checkedTACC}
        setCheckedTACC={setCheckedTACC}
        checkedGluten={checkedGluten}
        setCheckedGluten={setCheckedGluten}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        cartItems={cartItems}
        setCartItems={setCartItems}
        drawerOpenCart={drawerOpenCart}
        setDrawerOpenCart={setDrawerOpenCart}
      />
    </Box>
  );
}

export default HomePage;
